import {IDictionary, IEvent, IStore} from "modules/types";
import {createSelector} from "reselect";
import {find, findLast, get, keyBy, last} from "lodash";

export const getEvents = ({events}: IStore) => events;
export const getScheduledEvents = ({events}: IStore) =>
	events.filter(({status}) => status === "scheduled");
export const getNonScheduledEvents = ({events}: IStore) =>
	events.filter(({status}) => status !== "scheduled");
export const getEventsBetweenIds = (start_event_id: number, end_event_id?: number) =>
	createSelector(getNonScheduledEvents, getEventsById, (events, events_by_id) => {
		if (!events_by_id) {
			return [];
		}
		const last_pos = end_event_id ? events_by_id[end_event_id]?.position : 100;
		const first_pos = events_by_id[start_event_id]?.position;
		return events.filter(({position}) => position >= first_pos && position <= last_pos);
	});
export const getEventsById = createSelector(getEvents, (events) => keyBy(events, "id"));

export const getEventsByPosition = createSelector(getEvents, (events) => keyBy(events, "position"));

export const getActualEvent = createSelector(
	getEvents,
	(events) => find(events, ({status}) => ["scheduled", "active"].includes(status)) || last(events)
);

export const getActiveEvent = createSelector(getEvents, (events) =>
	findLast(events, ({status}) => status === "active")
);

export const getLastCompleteEvent = createSelector(getEvents, (events) =>
	findLast(events, ({status}) => status === "complete")
);

export const getActiveOrLastCompleteEvent = createSelector(
	getActiveEvent,
	getLastCompleteEvent,
	getActualEvent,
	(active_event, last_event, actual_event) => active_event || last_event || actual_event
);

export const getActualEventID = createSelector(getActualEvent, (event) => get(event, "id", 1));

export const getActualEventPosition = createSelector(getActualEvent, (event) =>
	get(event, "position", 1)
);

export const getSelectedEvent = (event_id?: string | number) =>
	createSelector(getActualEvent, getEventsById, (event, events_by_id: IDictionary<IEvent>) =>
		event_id ? events_by_id[event_id] : event
	);

export const isSelectedEventLocked = (event_id?: string | number) =>
	createSelector(getSelectedEvent(event_id), (selected_event) => {
		if (selected_event) {
			return selected_event.status !== "scheduled";
		}

		return false;
	});

export const getSelectedEventID = (event_id?: string) =>
	createSelector(getSelectedEvent(event_id), (event) => get(event, "id") || 1);

export const isEventLive = createSelector(
	getActualEvent,
	(event) => event && event.status === "active"
);

export const getNextLockoutDate = createSelector(getEvents, (events) =>
	get(find(events, {status: "scheduled"}), "start")
);

export const getEventById = (event_id: number) =>
	createSelector(getEvents, (events) => find(events, {id: event_id}));

export const getEventByIdSelector = createSelector(
	getEvents,
	(events) => (event_id: number) => find(events, {id: event_id})
);

export const isPreseason = createSelector(getEvents, (events) => {
	const first_event = find(events, ({position}) => position === 1);
	return first_event ? first_event.status === "scheduled" : true;
});

export const getScoringEventId = createSelector(
	isPreseason,
	getEvents,
	getLastCompleteEvent,
	getActualEvent,
	(is_preseason, events, last_complete_event, actual_event) => {
		let event = actual_event;

		if (is_preseason) {
			// event = first(events);
			event = last(events);
		} else if (actual_event && actual_event.status === "scheduled") {
			event = last_complete_event;
		}

		return get(event, "id", 0);
	}
);
